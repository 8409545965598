.reaction_item:hover .reaction_item_underline {
  text-decoration: underline;
  transition: all 0.2s;
}

.reaction_tabs {
  position: absolute;
  width: 100%;
  z-index: 2;
  left: 0rem;
  top: 0rem;
  padding: 0.75rem 0.5rem 0rem 0.5rem;
}

.reaction_tabs_light_background {
  background-color: #faf7f3;
  border-bottom: 1px solid #e7e3df;
}

.reaction_tabs_dark_background {
  background-color: #1a1a1a;
  border-bottom: 1px solid #090909;
}
