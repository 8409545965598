.preload {
  transition: 'none' !important;
  animation-duration: 0ms !important;
  animation-delay: 0ms !important;
}

@keyframes unsaved_top_right_animation {
  0% {
    clip-path: polygon(0 0, 50% 50%, 100% 100%);
  }
  20% {
    clip-path: polygon(0 0, 60% 40%, 100% 100%);
  }
  40% {
    clip-path: polygon(0 0, 70% 30%, 100% 100%);
  }
  60% {
    clip-path: polygon(0 0, 80% 20%, 100% 100%);
  }
  80% {
    clip-path: polygon(0 0, 90% 10%, 100% 100%);
  }
  100% {
    // A 5 corner polygon is used to cover the drop shadow behind it
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 98% 100%, 0% 2%);
  }
}

.unsaved_top_right {
  clip-path: polygon(0 0, 50% 50%, 100% 100%);
  animation: unsaved_top_right_animation 0.25s linear 0.25s;
  animation-fill-mode: forwards;
  //   clip-path: polygon(0 0, 100% 0%, 100% 100%);
}

@keyframes saved_top_right_animation {
  0% {
    // A 5 corner polygon is used to cover the drop shadow behind it
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 98% 100%, 0% 2%);
  }
  20% {
    clip-path: polygon(0 0, 90% 10%, 100% 100%, 98% 100%, 0% 2%);
  }
  40% {
    clip-path: polygon(0 0, 80% 20%, 100% 100%, 98% 100%, 0% 2%);
  }
  60% {
    clip-path: polygon(0 0, 70% 30%, 100% 100%, 98% 100%, 0% 2%);
  }
  80% {
    clip-path: polygon(0 0, 60% 40%, 100% 100%, 98% 100%, 0% 2%);
  }
  100% {
    clip-path: polygon(0 0, 50% 50%, 100% 100%);
  }
}

.saved_top_right {
  animation: saved_top_right_animation 0.25s linear;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

@keyframes unsaved_bottom_left_animation {
  0% {
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
  20% {
    clip-path: polygon(0 0, 10% 90%, 100% 100%);
  }
  40% {
    clip-path: polygon(0 0, 20% 80%, 100% 100%);
  }
  60% {
    clip-path: polygon(0 0, 30% 70%, 100% 100%);
  }
  80% {
    clip-path: polygon(0 0, 40% 60%, 100% 100%);
  }
  100% {
    clip-path: polygon(0 0, 50% 50%, 100% 100%);
  }
}

.unsaved_bottom_left {
  animation: unsaved_bottom_left_animation 0.25s linear;
  clip-path: polygon(0 0, 50% 50%, 100% 100%);
}

@keyframes saved_bottom_left_animation {
  0% {
    clip-path: polygon(0 0, 50% 50%, 100% 100%);
  }
  20% {
    clip-path: polygon(0 0, 40% 60%, 100% 100%);
  }
  40% {
    clip-path: polygon(0 0, 30% 70%, 100% 100%);
  }
  60% {
    clip-path: polygon(0 0, 20% 80%, 100% 100%);
  }
  80% {
    clip-path: polygon(0 0, 10% 90%, 100% 100%);
  }
  100% {
    // A 5 corner polygon is used to cover the drop shadow behind it
    clip-path: polygon(0 0, 0% 100%, 100% 100%, 100% 98%, 2% 0%);
  }
}

.saved_bottom_left {
  clip-path: polygon(0 0, 50% 50%, 100% 100%);
  animation: saved_bottom_left_animation 0.25s linear 0.25s;
  animation-fill-mode: forwards;
}

.initial_saved_bottom_left {
  clip-path: polygon(0 0, 0% 100%, 100% 100%, 100% 98%, 2% 0%);
}

.initial_saved_top_right {
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.initial_unsaved_bottom_left {
  clip-path: polygon(0 0, 50% 50%, 100% 100%);
}

.initial_unsaved_top_right {
  clip-path: polygon(0 0, 50% 50%, 100% 100%);
}
