.stashed_expand_animation:hover + .stash_animation {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  -webkit-transform: translate(0em, -1em);
  -moz-transform: translate(0em, -1em);
  -o-transform: translate(0em, -1em);
  -ms-transform: translate(0em, -1em);
  transform: translate(0em, -1em);
  transition: all 0.2s;
}

.stashed_collapse_animation:hover + .stash_animation {
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  -webkit-transform: translate(0em, 0em);
  -moz-transform: translate(0em, 0em);
  -o-transform: translate(0em, 0em);
  -ms-transform: translate(0em, 0em);
  transform: translate(0em, 0em);
  transition: all 0.1s;
}
