//Scroll light theme

.scrollLight {
  color: #f2efeb;
  transition: all 0.2s ease-out;
}

.scrollLight:hover {
  color: #828595;
}

.scrollLight::-webkit-scrollbar {
  height: 10px;
}
.scrollLight::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollLight::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollLight::-webkit-scrollbar-thumb:hover {
  color: #743c7a;
}

//Scroll dark theme

.scrollDark {
  color: #141313;
  transition: all 0.2s ease-out;
}

.scrollDark:hover {
  color: #999aa3;
}

.scrollDark::-webkit-scrollbar {
  height: 10px;
}
.scrollDark::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollDark::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollDark::-webkit-scrollbar-thumb:hover {
  color: #b786bb;
}

// Scroll light theme with visible track

.scrollWithTrackLight {
  color: #828595;
  transition: all 0.2s ease-out;
}

.scrollWithTrackLight::-webkit-scrollbar {
  height: 10px;
}
.scrollWithTrackLight::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #c8c9ce;
}

.scrollWithTrackLight::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollWithTrackLight::-webkit-scrollbar-thumb:hover {
  color: #743c7a;
}

// Scroll dark theme with visible track

.scrollWithTrackDark {
  color: #999aa3;
  transition: all 0.2s ease-out;
}

.scrollWithTrackDark::-webkit-scrollbar {
  height: 10px;
}
.scrollWithTrackDark::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #c8c9ce;
}

.scrollWithTrackDark::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollWithTrackDark::-webkit-scrollbar-thumb:hover {
  color: #b786bb;
}

// Scroll vertical light theme

.scrollVerticalLight {
  color: #f2efeb;
  transition: all 0.2s ease-out;
}

.scrollVerticalLight:hover {
  color: #828595;
}

.scrollVerticalLight::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.scrollVerticalLight::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollVerticalLight::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollVerticalLight::-webkit-scrollbar-thumb:hover {
  color: #743c7a;
}

// Scroll vertical dark theme

.scrollVerticalDark {
  color: #141313;
  transition: all 0.2s ease-out;
}

.scrollVerticalDark:hover {
  color: #999aa3;
}

.scrollVerticalDark::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.scrollVerticalDark::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollVerticalDark::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollVerticalDark::-webkit-scrollbar-thumb:hover {
  color: #b786bb;
}

// Scroll vertical light surface theme

.scrollVerticalLightSurface {
  color: #faf7f3;
  transition: all 0.2s ease-out;
}

.scrollVerticalLightSurface:hover {
  color: #828595;
}

.scrollVerticalLightSurface::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.scrollVerticalLightSurface::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollVerticalLightSurface::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollVerticalLightSurface::-webkit-scrollbar-thumb:hover {
  color: #743c7a;
}

// Scroll vertical dark surface theme

.scrollVerticalDarkSurface {
  color: #1a1a1a;
  transition: all 0.2s ease-out;
}

.scrollVerticalDarkSurface:hover {
  color: #999aa3;
}

.scrollVerticalDarkSurface::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.scrollVerticalDarkSurface::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollVerticalDarkSurface::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollVerticalDarkSurface::-webkit-scrollbar-thumb:hover {
  color: #b786bb;
}

//Scroll vertical light theme with visible track

.scrollVerticalWithTrackLight {
  color: #828595;
  transition: all 0.2s ease-out;
}

.scrollVerticalWithTrackLight::-webkit-scrollbar {
  width: 10px;
}
.scrollVerticalWithTrackLight::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #c8c9ce;
}

.scrollVerticalWithTrackLight::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollVerticalWithTrackLight::-webkit-scrollbar-thumb:hover {
  color: #743c7a;
}

//Scroll vertical dark theme with visible track

.scrollVerticalWithTrackDark {
  color: #999aa3;
  transition: all 0.2s ease-out;
}

.scrollVerticalWithTrackDark::-webkit-scrollbar {
  width: 10px;
}
.scrollVerticalWithTrackDark::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #c8c9ce;
}

.scrollVerticalWithTrackDark::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 10px;
}

.scrollVerticalWithTrackDark::-webkit-scrollbar-thumb:hover {
  color: #b786bb;
}

//Scroll vertical light theme with visible track slim variant

.scrollVerticalSlimWithTrackLight {
  color: #828595;
  transition: all 0.2s ease-out;
}

.scrollVerticalSlimWithTrackLight::-webkit-scrollbar {
  width: 5px;
}
.scrollVerticalSlimWithTrackLight::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #c8c9ce;
}

.scrollVerticalSlimWithTrackLight::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 8px;
}

.scrollVerticalSlimWithTrackLight::-webkit-scrollbar-thumb:hover {
  color: #743c7a;
}

//Scroll vertical dark theme with visible track slim variant

.scrollVerticalSlimWithTrackDark {
  color: #999aa3;
  transition: all 0.2s ease-out;
}

.scrollVerticalSlimWithTrackDark::-webkit-scrollbar {
  width: 5px;
}
.scrollVerticalSlimWithTrackDark::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #c8c9ce;
}

.scrollVerticalSlimWithTrackDark::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
  border-radius: 8px;
}

.scrollVerticalSlimWithTrackDark::-webkit-scrollbar-thumb:hover {
  color: #b786bb;
}

//Scroll verticcal light theme with visible track and no border radius

.scrollVerticalWithTrackBlockLight {
  color: #828595;
  transition: all 0.2s ease-out;
}

.scrollVerticalWithTrackBlockLight::-webkit-scrollbar {
  width: 10px;
}
.scrollVerticalWithTrackBlockLight::-webkit-scrollbar-track {
  background-color: #c8c9ce;
}

.scrollVerticalWithTrackBlockLight::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
}

.scrollVerticalWithTrackBlockLight::-webkit-scrollbar-thumb:hover {
  color: #743c7a;
}

//Scroll verticcal dark theme with visible track and no border radius

.scrollVerticalWithTrackBlockDark {
  color: #999aa3;
  transition: all 0.2s ease-out;
}

.scrollVerticalWithTrackBlockDark::-webkit-scrollbar {
  width: 10px;
}
.scrollVerticalWithTrackBlockDark::-webkit-scrollbar-track {
  background-color: #c8c9ce;
}

.scrollVerticalWithTrackBlockDark::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
  background-clip: content-box;
}

.scrollVerticalWithTrackBlockDark::-webkit-scrollbar-thumb:hover {
  color: #b786bb;
}
