.tabs {
  display: flex;
  flex-direction: row;
}

.tab {
  display: inline-block;
}

.tab_panel {
  display: flex;
  flex-direction: column;
}
